<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item label="机构名称">
          <el-input v-model="mechanism_name"></el-input>
        </el-form-item>
        <el-form-item label="课程名称">
          <el-input v-model="title"></el-input>
        </el-form-item>
      <el-form-item>
         <el-form-item label="城市">
            <el-cascader
              clearable
              v-model="querycityList"
              :options="cityoptions"
              :props="cityProps"
              placeholder="城市"
            ></el-cascader>
          </el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
 <el-button type="success" @click="openAddCourse">创建课程</el-button>
        
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
     <template #price="{ row }">
        <div>
          <p>
            售价{{row.discount_amout}}元
          </p>
          <p v-if="row.consume > 0">
            消费金{{row.consume}}元
          </p>
        </div>
      </template>
     <template #category="{ row }">
        <div>
          <p>
            {{row.categories_child}}{{row.course_num}}节
          </p>
        </div>
      </template>
      <template #addr="{ row }">
        <div>
          <p>{{ row.mechanism_addr || row.venues_addr || '' }}</p>
          <p>离提交地址{{row.distance || ''}}</p>
        </div>
      </template>
      <template #status="{ row }">
        <div>
          <el-tag :hit="false" size="small" effect="plain" :type="statusMap[row.apply.status].tag">{{statusMap[row.apply.status].name}}</el-tag>
        </div>
      </template>
      <template #handler="{ row }">
        <div>
          <el-button type="primary" size="small" @click="changeCourse(row)"
            >选择课程</el-button
          > 
        </div>
      </template>
    </auto-table>
     <addCourse
      mode="add"
      ref="addCou"
      @success="getList(1)"
    ></addCourse>
  </div>
</template>

<script>

import {getDistance} from '@/utils/utils.js'
import addCourse from '../../../courseManage/components/course/addCourse.vue';
export default {
  components:{
    addCourse
  },
    props:{
        apply:{
            type:Object,
            default:()=>{return {}}
        }
    },
  data() {
    return {
      DataList: [],
      Option: [
        { name: "名称", value: "title" },
        { name: "价格", value: "price",type: "custom"  },
        { name: "类目", value: "category",type: "custom"  },
        { name: "机构", value: "mechanism_name" },
        { name: "城市", value: "district" },
        { name: "地址", value: "addr",type: "custom"  },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      finished: false,
       managerInfo: JSON.parse(localStorage.getItem("managerInfo")),
       statusMap:[],
       typeMap:[],
      cityoptions : JSON.parse(localStorage.getItem("addrList")),
      cityProps: {
           checkStrictly: true,
        value: "district",
        label: "district",
        children: "childrens",
      },
      cityList: [],
      querycityList: [],
      mechanism_name:'',
      title:''
    };
  },
  watch:{
    apply:{
        handler:function(nv){
            if(nv.id){
                this.getList(1);
            }
        },
        immediate:true
    }
  },


  methods: {
    openAddCourse() {
      this.$refs.addCou.open();
    },
    changeCourse(row){
        this.$confirm(`为用户匹配[${row.title}]课程`).then(()=>{
            let data = {
                apply_id:this.apply.id,
                admin_id:this.managerInfo.id,
                course_id:row.id
            }
            this.$post('/user/disApplyFlow/matchCourse',data).then(res=>{
                this.$message('绑定成功')
                this.$emit('suc',{})
            })
      })
    },
    details(row){
      this.$router.push({name:'applyFlowDetail',query:{id:row.apply.id}})
    },
    //获取列表
    getList(v) {
      let page = v || 1;

      let data = {
        currentPage: page,
        pageSize: 10,
        status: 2,
        latitude: this.apply.latitude,
        longitude: this.apply.longitude,
        city: this.apply.city,
        category:this.apply.category,
        mechanism_name:this.mechanism_name || null,
        title:this.title || null
      }

     if(this.querycityList && this.querycityList.length > 0){
         data.province = this.querycityList[0]
         data.city = this.querycityList[1]
         data.district = this.querycityList[2]
      }



      this.$axios({
        url: "/user/discountApply/queryCourse",
        params: data,
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.DataList.forEach(item=>item.distance = getDistance(item.latitude, item.longitude,this.apply.latitude, this.apply.longitude))
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.type-dis{
  color: rgb(83, 163, 238);
}
.type-spl{
 color: rgb(243, 160, 64);
}
</style>